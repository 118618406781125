import './plugins/axios'
import { createApp } from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import VueMask from '@devindex/vue-mask'; // <-- ADD THIS LINE
import './registerServiceWorker'

loadFonts()

createApp(App)
  .use(vuetify)
  .use(VueMask)
  .mount('#app')
