export const useSchedulerProConfig = () => {
    return {
        columns   : [{ text : 'Машина', field : 'name',  width : 160 }],
        startDate : new Date(2023, 8, 7),
        endDate   : new Date(2023, 8, 10)
    };
};

export const useProjectConfig = () => {
    return {
    };
};