/* eslint-disable */
<template>
  <v-card>
    <v-tabs
      v-model="tab"
      bg-color="primary"
    >
      <v-tab value="one">Новый договор</v-tab>
      <v-tab value="two">Календарь</v-tab>
    </v-tabs>

    <v-card-text>
      <v-window v-model="tab">
        <v-window-item value="one">
          <div>
  <v-container>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      width="1024"
    >
      <template v-slot:activator="{ props }">
        <v-btn
          color="primary"
          v-bind="props"
        >
Поиск клиента        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Поиск клиента</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  label="Серия паспорта"
                  v-model="SearchS"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  label="Номер паспорта"
                  v-model="SearchN"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
  
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialog = false"
          >
            Закрыть
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="searchClient()"
          >
            Поиск
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
  </v-container>
  <v-form v-model="valid">
    <v-container>
      <v-row>
        <v-col
            cols="12"
            md="4"
        >
          <v-select
              label="Выберите машину"
              :items="autos"
              item-title="name"
              item-value="id"
              v-model="Auto"
              required
          ></v-select>
        </v-col>

        <v-col
            cols="12"
            md="4"
        >
          <v-text-field
              label="Номер договора"
              required
              v-model="number"
          ></v-text-field>
        </v-col>

        <v-col
            cols="12"
            md="4"
        >
          <v-text-field
              label="Расчетное время"
              required
              v-mask="'00:00'"
              v-model="time"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            cols="12"
            md="4"
        >
          <v-text-field
              label="Фамилия"
              required
              @change="voditeli[0][0].value = first"
              v-model="first"
          ></v-text-field>
        </v-col>

        <v-col
            cols="12"
            md="4"
        >
          <v-text-field
              label="Имя"
              required
              @change="voditeli[0][1].value = name"
              v-model="name"
          ></v-text-field>
        </v-col>

        <v-col
            cols="12"
            md="4"
        >
          <v-text-field
              label="Отчество"
              required
              @change="voditeli[0][2].value = lastname"
              v-model="lastname"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            cols="12"
            md="4"
        >
          <v-text-field
              label="Дата рождения"
              required
              v-mask="'00.00.0000'"
              @change="voditeli[0][6].value = birthday"
              v-model="birthday"
          ></v-text-field>
        </v-col>

        <v-col
            cols="12"
            md="4"
        >
          <v-text-field
              label="Номер телефона"
              required
              v-mask="'+0-000-000-0000'"
              v-model="phone"
          ></v-text-field>
        </v-col>

        <v-col
            cols="12"
            md="4"
        >
          <v-text-field
              label="Серия паспорта"
              required
              v-mask="'0000'"
              v-model="serP"
              @change="voditeli[0][10].value = serP"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            cols="12"
            md="4"
        >
          <v-text-field
              label="Номер паспорта"
              required
              v-mask="'000000'"
              v-model="numP"
              @change="voditeli[0][11].value = numP"
          ></v-text-field>
        </v-col>

        <v-col
            cols="12"
            md="4"
        >
          <v-text-field
              label="Дата выдачи паспорта"
              required
              v-mask="'00.00.0000'"
              v-model="dateP"
              @change="voditeli[0][12].value = dateP"
          ></v-text-field>
        </v-col>

        <v-col
            cols="12"
            md="4"
        >
          <v-text-field
              label="Кем выдан"
              required
              v-model="MVD"
              @change="voditeli[0][13].value = MVD"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            cols="12"
            md="4"
        >
          <v-text-field
              label="Адрес прописки"
              required
              @change="voditeli[0][14].value = address"
              v-model="address"
          ></v-text-field>
        </v-col>

        <v-col
            cols="12"
            md="4"
        >
          <v-text-field
              label="Сумма в сутки"
              required
              v-model="summ"
          ></v-text-field>
        </v-col>

        <v-col
            cols="12"
            md="4"
        >
          <v-text-field
              label="Дата начала"
              required
              v-mask="'00.00.0000'"
              v-model="dateN"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
            cols="12"
            md="4"
        >
          <v-text-field
              label="Дата окончания"
              required
              v-mask="'00.00.0000'"
              v-model="dateC"
          ></v-text-field>
        </v-col>

        <v-col
            cols="12"
            md="4"
        >
          <v-text-field
              label="Пробег"
              required
              v-model="probeg"
          ></v-text-field>
        </v-col>

      </v-row>
      <v-row>
        <v-col class="text-center">
          <h3 class="text-h5">Водители</h3>

          <br>
          <v-btn @click="AddVod">Добавить водителя</v-btn>
          
          <v-row v-for="vod in voditeli" :key="vod">
<!-- 
            <v-col class="text-center">
          <h3 class="text-h5">Водитель  {{ count(thivoditeli) }}</h3>
            </v-col> -->


            <v-col
                cols="12"
                md="4"
                v-for="v in vod"
                :key="v"
            >
              <v-text-field
                  :label=v.name
                  required
                 
                  
                  v-model="v.value"
              ></v-text-field>
            </v-col>
            <v-divider></v-divider>
          </v-row>

        </v-col>
      </v-row>
      <br>
      <v-col class="text-center">
      <v-btn @click="getFile">Скачать договор</v-btn>
      <v-btn @click="SendWhatsApp">Отправить на подпись</v-btn>

    </v-col>
    </v-container>
  </v-form>
</div>
        </v-window-item>

        <v-window-item value="two">
          <v-container>
            <v-row>
          <bryntum-scheduler-pro
       
        :resources = "resources"
        :events = "events"
        
        v-bind = "schedulerProConfig"
    />
    </v-row>
    </v-container>
        </v-window-item>

      </v-window>
    </v-card-text>
  </v-card>

</template>

<script>
/* eslint-disable */

const axios = require('axios');


import { BryntumSchedulerPro, LocaleManager, LocaleHelper } from '@bryntum/schedulerpro-vue-3';

import { useSchedulerProConfig } from '@/AppConfig';

import { ref, reactive } from 'vue';


export default {
  name: 'HelloWorld',
   components : {
        BryntumSchedulerPro
    },
 setup() {
        const schedulerProConfig = reactive(useSchedulerProConfig());


        return {
            schedulerProConfig,
      
        };
    },
  data: () => ({
     events : [
      { id : 1, name : 'Event', startDate : new Date(2018, 8, 27), duration : 1 },
      
    ],
  
    resources : [
      
      
    ],
   selectedDate: new Date(2018, 1, 15),
          eventSettings: {
            dataSource: [{
              Id: 1,
              Subject: 'Meeting',
              StartTime: new Date(2018, 1, 15, 10, 0),
              EndTime: new Date(2018, 1, 15, 12, 30)
            }]
          },
    autos: [],
    dialog: false,
    SearchN: '',
    SearchS: '',
    voditeli: [[
      {
        name: 'Фамилия водителя',
        value: name,
      },
      {
        name: 'Имя водителя',
        value: '',
      },
      {
        name: 'Отчество водителя',
        value: '',
      },
      {
        name: 'Серия Прав',
        value: '',
      },
      {
        name: 'Номер прав',
        value: '',
      },
      {
        name: 'Кем выданы права',
        value: '',
      },
      {
        name: 'Дата рождения водителя',
        value: '',
      },
      {
        name: 'Дата начала стажа водителя',
        value: '',
      },
      {
        name: 'Дата выдачи прав водителя',
        value: '',
      },
      {
        name: 'Дата окончания прав водителя',
        value: '',
      },
      
      {
        name: 'Серия паспорта',
        value: '',
      },
      
      {
        name: 'Номер паспорта',
        value: '',
      },
      
      {
        name: 'Дата выдачи паспорта',
        value: '',
      },
      {
        name: 'Кем выдан паспорт',
        value: '',
      },
      {
        name: 'Адрес прописки',
        value: '',
      },
    ]],
    name: '',
    first: '',
    splits: [{ label: 'John', class: 'john' }, { label: 'Kate', class: 'kate' }],
    lastname: '',
    birthday: '',
    Auto: '',
    number: '',
    time: '',
    phone: '',
    serP: '',
    tab: null,
    numP: '',
    dateP: '',
    MVD: '',
    address: '',
    summ: '',
    dateN: '',
    dateC:'',
    probeg: '',
  }),


    
  created(){
    let self = this;
    axios.get('https://admin.euromotorsufa.ru/api/allAuto')
    .then(function (response) {
    // handle success
    console.log(response);
    response.data.forEach((response) => {
    let name = '';
    name = response.Mark + ' ' + response.model + ' ' + response.GosNomer;
    self.autos.push({
      'id' : response.id,
      'name' : name,

    });
    self.resources.push({
      'id' : response.id,
      'name' : name,

    })
})
  })
  .catch(function (error) {
    // handle error
    console.log(error);
  })
  .finally(function () {
    // always executed
  });

  axios.get('https://admin.euromotorsufa.ru/api/getEvents')
    .then(function (response) {
    // handle success
    self.events = response.data.data
    console.log(self.events)
  })

  },
  updated(){
    this.voditeli[0][0].value = this.name
  },
  methods:{
    AddVod(){
      this.voditeli.push([
        {
          name: 'Фамилия водителя',
          value: '',
        },
        {
          name: 'Имя водителя',
          value: '',
        },
        {
          name: 'Отчество водителя',
          value: '',
        },
        {
          name: 'Серия Прав',
          value: '',
        },
        {
          name: 'Номер прав',
          value: '',
        },
        {
          name: 'Кем выданы права',
          value: '',
        },
        {
          name: 'Дата рождения водителя',
          value: '',
        },
        {
          name: 'Дата начала стажа водителя',
          value: '',
        },
        {
          name: 'Дата выдачи прав водителя',
          value: '',
        },
        {
          name: 'Дата окончания прав водителя',
          value: '',
        },
        
      {
        name: 'Серия паспорта',
        value: '',
      },
      
      {
        name: 'Номер паспорта',
        value: '',
      },
      
      {
        name: 'Дата выдачи паспорта',
        value: '',
      },
      {
        name: 'Кем выдан паспорт',
        value: '',
      },
      {
        name: 'Адрес прописки',
        value: '',
      },
      ])
    },
    getFile(){
       axios.post('https://admin.euromotorsufa.ru/api/getZip', {
         name: this.name,
         first: this.first,
         lastname: this.lastname,
         birthday: this.birthday,
         Auto: this.Auto,
         number: this.number,
         time: this.time,
         phone: this.phone,
         serP: this.serP,
         numP: this.numP,
         dateP: this.dateP,
         MVD: this.MVD,
        address: this.address,
        summ: this.summ,
         dateN: this.dateN,
         dateC: this.dateC,
         probeg: this.probeg,
          vod: this.voditeli
       })
        .then(function (response) {
window.location.href = 'https://admin.euromotorsufa.ru/' + response.data
  });
    },
    SendWhatsApp(){
       axios.post('https://admin.euromotorsufa.ru/api/sendWhatsApp', {
         name: this.name,
         first: this.first,
         lastname: this.lastname,
         birthday: this.birthday,
         Auto: this.Auto,
         number: this.number,
         time: this.time,
         phone: this.phone,
         serP: this.serP,
         numP: this.numP,
         dateP: this.dateP,
         MVD: this.MVD,
        address: this.address,
        summ: this.summ,
         dateN: this.dateN,
         dateC: this.dateC,
         probeg: this.probeg,
          vod: this.voditeli
       })
        .then(function (response) {
  });
    },
    searchClient(){
      let self = this
      axios.post('https://admin.euromotorsufa.ru/api/searchClient', {
         serial: this.SearchS,
         number: this.SearchN
       })
        .then(function (response) {
          self.name = response.data.lastname
          self.first = response.data.firstname
          self.lastname = response.data.patr
          self.phone = response.data.phone
          self.MVD = response.data.MVD
          self.address = response.data.address
          self.numP = response.data.passport_number
          self.serP = response.data.passport_serial
          self.dateP = response.data.dateP
          self.birthday = response.data.bday
          self.voditeli[0][0].value =response.data.lastname
          self.voditeli[0][1].value = response.data.firstname
          self.voditeli[0][2].value = response.data.patr
          self.voditeli[0][3].value = response.data.seralPrav
          self.voditeli[0][4].value = response.data.numberPrav
          self.voditeli[0][5].value = response.data.GAI
          self.voditeli[0][6].value = response.data.bday
          self.voditeli[0][7].value = response.data.DateS
          self.voditeli[0][8].value = response.data.DateV
          self.voditeli[0][9].value = response.data.DateCancel
          self.voditeli[0][10].value = response.data.passport_serial
          self.voditeli[0][11].value = response.data.passport_number
          self.voditeli[0][12].value = response.data.dateP
          self.voditeli[0][13].value = response.data.MVD
          self.voditeli[0][14].value = response.data.address
          self.SearchS = ''
          self.SearchN = ''
          self.dialog = false
  });
    }
  }
}
</script>
<style lang = "scss">
@import './App.scss';
</style>
